import i18n from '@/i18n'

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const checkIsLink = url => {
  try {
    return new URL(url)
  } catch (e) {
    return false
  }
}

export const isLinkGTRToBo = item => {
  return item.gtrbo && item.url
}

export const isExternalLink = item => {
  return !item.gtrbo && item.url
}

export const stringToSlug = str => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w\s]+/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase()
}

export const getRedirectedRouteIfExist = routeName => {
  const routeNeedRedirect = ['Location', 'FrontOffice', 'LocationsGmb', 'GmbQa']
  const routeRedirect = [{ name: 'Locations' }, { name: 'FrontOffices' }, { name: 'LocationsGmb' }, { name: 'GmbQa' }]
  if (routeNeedRedirect.includes(routeName)) {
    return { isRedirect: true, route: routeRedirect[routeNeedRedirect.indexOf(routeName)] }
  }
  return { isRedirect: false, routeName: '' }
}

export const pascalCaseToUnderscore = str => {
  return str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase()
}

export const capitalizeString = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const numberFormat = ({
  number,
  style = 'decimal',
  currency = 'EUR',
  display = 'short',
  digit = 1,
  notation = 'compact',
}) => {
  return new Intl.NumberFormat(i18n.locale, {
    style,
    currency,
    notation,
    minimumFractionDigits: 0,
    maximumFractionDigits: digit,
    compactDisplay: display,
  }).format(number)
}

export const clone = obj => {
  return JSON.parse(JSON.stringify(obj))
}
