import router from '@/router'
import { getAuthApi, getMenusApi, forgetPassword, updateSettings, updatePwd } from '@/services/auth.service'
import { getLocalesApi } from '@/services/client.service'
import { countries } from '@/config/countries.config'
import { getShortLocal } from '@/utils/string.util'

import Vue from 'vue'
import i18n from '@/i18n'

const SET_IS_BRANDS_OPEN = 'SET_IS_BRANDS_OPEN'
const SET_DARK_THEME = 'SET_DARK_THEME'
const SET_IS_LOGGED = 'SET_IS_LOGGED'
const SET_CURRENT_USER = 'SET_CURRENT_USER'
const SET_CURRENT_MODULE = 'SET_CURRENT_MODULE'
const SET_NAVIGATION = 'SET_NAVIGATION'
const SET_CURRENT_NAVIGATION = 'SET_CURRENT_NAVIGATION'
const SET_MODULES = 'SET_MODULES'
const SET_LOADING = 'SET_LOADING'
const SET_UPDATING = 'SET_UPDATING'
const RESET_STATE = 'RESET_STATE'

// Specific to BO V2
const SET_LOCALES = 'SET_LOCALES'
const SET_COUNTRIES = 'SET_COUNTRIES'
const SET_DRAWER = 'SET_DRAWER'

const getDefaultState = () => {
  return {
    drawer: null,
    darkTheme: !!(localStorage.getItem('dark_theme') * 1) || false,
    isBrandsOpen: false,
    loading: true,
    updating: false,
    isLogged: false,
    currentUser: null,
    currentModule: null,
    navigation: [],
    currentNavigation: [],
    modules: [],

    // Specific to BO V2
    locales: [],
    countries: [],
  }
}

const state = getDefaultState()

const mutations = {
  [SET_IS_BRANDS_OPEN]: (state, boolean) => {
    state.isBrandsOpen = boolean
  },
  [SET_DARK_THEME]: (state, boolean) => {
    state.darkTheme = boolean
    localStorage.setItem('dark_theme', +boolean)
  },
  [SET_IS_LOGGED]: (state, boolean) => {
    state.isLogged = boolean
  },
  [SET_CURRENT_USER]: (state, user) => {
    state.currentUser = user
    i18n.locale = user.locale
  },
  [SET_CURRENT_MODULE]: (state, module) => {
    state.currentModule = module
  },
  [SET_NAVIGATION]: (state, navigation) => {
    state.navigation = navigation
  },
  [SET_CURRENT_NAVIGATION]: (state, navigation) => {
    state.currentNavigation = navigation
  },
  [SET_MODULES]: (state, modules) => {
    state.modules = modules
  },
  [SET_LOADING]: (state, boolean) => {
    state.loading = boolean
  },
  [SET_UPDATING]: (state, boolean) => {
    state.updating = boolean
  },
  [RESET_STATE]: state => {
    Object.assign(state, getDefaultState())
  },

  // Specific to BO V2
  [SET_LOCALES]: (state, locales) => {
    state.locales = locales
  },
  [SET_COUNTRIES]: (state, countries) => {
    state.countries = countries
  },
  [SET_DRAWER]: (state, boolean) => {
    state.drawer = boolean
  },
}

const actions = {
  async userLogout({ dispatch }) {
    dispatch('resetState')
  },
  async getAuthentication({ commit, dispatch, rootState }) {
    const localStorageClientId = localStorage.getItem('clientId')
    const paramsClientId = router.currentRoute.params.clientId
    const { user, clients, menus, modules } = await getAuthApi()

    commit(SET_CURRENT_USER, user)
    commit(SET_COUNTRIES, countries[getShortLocal(user.locale)])
    commit('client/SET_CLIENTS', clients, { root: true })
    commit(SET_NAVIGATION, menus)

    // Ensure client Uuid is valid
    const currentClient = clients.find(client => client.uuid === (paramsClientId || localStorageClientId)) || clients[0]

    await Promise.all([
      dispatch('client/getClient', currentClient.uuid, { root: true }),
      dispatch('manageModules', modules),
    ])
    dispatch('updateCurrentNavigation', rootState.backoffice.currentModule)

    commit(SET_IS_LOGGED, true)
  },
  async manageModules({ commit }, modules) {
    const { params, query } = router.currentRoute
    const moduleName = params.module || query.module
    let currentModule = modules.find(module => module.module === moduleName) || modules.find(m => m.default)

    if (!currentModule) currentModule = modules[0]

    commit(SET_MODULES, modules)
    commit(SET_CURRENT_MODULE, currentModule.module)
  },
  updateCurrentNavigation({ commit, rootState }, currentModule) {
    const currentNavigation = rootState.backoffice.navigation[currentModule]

    commit(SET_CURRENT_NAVIGATION, currentNavigation)
  },
  async getMenus({ commit }, module) {
    const { data } = await getMenusApi(module)
    const navigation = data
      .map(nav => ({ ...nav, items: nav.items.filter(item => item.available) }))
      .filter(nav => nav.items.length)
    commit(SET_CURRENT_MODULE, module)
    commit(SET_NAVIGATION, navigation)
  },
  setLoading({ commit }, bool) {
    commit(SET_LOADING, bool)
  },
  setUpdating({ commit }, bool) {
    commit(SET_UPDATING, bool)
  },
  resetState({ commit }) {
    commit(RESET_STATE)
  },

  // Specific to BO V2
  getLocales: async ({ commit }) => {
    const { results } = await getLocalesApi()
    commit(SET_LOCALES, results)
  },
  toggleDarkTheme: ({ commit, state }) => {
    if (state.darkTheme === true) {
      commit(SET_DARK_THEME, false)
    } else {
      commit(SET_DARK_THEME, true)
    }
  },
  toggleBrands: ({ commit, dispatch, state }, element) => {
    dispatch('closeEverything', { menu: true, notifications: true, account: true, brands: false })
    if (state.isBrandsOpen === true) {
      commit(SET_IS_BRANDS_OPEN, false)
    } else {
      commit(SET_IS_BRANDS_OPEN, true)
      Vue.prototype.$nextTick(() => {
        document.getElementById(element).getElementsByTagName('input')[0].focus()
      })
    }
  },
  closeEverything: ({ commit }, { brands }) => {
    if (brands) {
      commit(SET_IS_BRANDS_OPEN, false)
    }
  },
  userForgetPassword: (_, email) => {
    return forgetPassword(email)
      .then(response => Promise.resolve(response.data.token))
      .catch(() => Promise.reject())
  },
  resetAllStates: ({ dispatch }) => {
    dispatch('frontoffice/resetState', null, { root: true })
    dispatch('gmb/resetState', null, { root: true })
    dispatch('kpis/resetState', null, { root: true })
    dispatch('localPost/resetState', null, { root: true })
    dispatch('ohgroup/resetState', null, { root: true })
    dispatch('presencemanagement/resetState', null, { root: true })
    dispatch('qa/resetState', null, { root: true })
    dispatch('radar/resetState', null, { root: true })
    dispatch('userexpress/resetState', null, { root: true })
    localStorage.removeItem('frontoffice')
  },
  updateUser: ({ commit, state }, data) => {
    return updateSettings(data)
      .then(response => {
        commit(SET_CURRENT_USER, { ...state.currentUser, ...response.data })

        const userLocal = getShortLocal(state.currentUser.locale)
        commit(SET_COUNTRIES, countries[userLocal])
        return Promise.resolve()
      })
      .catch(e => Promise.reject(e))
  },
  updatePassword: (_, data) => {
    return updatePwd(data.password)
      .then(() => Promise.resolve())
      .catch(e => Promise.reject(e))
  },
  setDrawer({ commit }, bool) {
    commit(SET_DRAWER, bool)
  },
}

const getters = {
  firstParentMenu: state => state.navigation[0],
  firstChildMenu: state => state.navigation[0].items[0],
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
